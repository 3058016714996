<script setup lang="ts">
import { PhWarning } from '@phosphor-icons/vue';
import { computed, Ref, ref, watch } from 'vue';
import { DraggableEvent, VueDraggable } from 'vue-draggable-plus';
import { useMutation, useQuery } from 'vue-query';

import benefitGroupApi from '@/api/benefitGroup';
import webAppServiceApi from '@/api/webAppService';
import useVisibilityTimeout from '@/composables/useVisibilityTimeout';
import { BenefitGroup } from '@/types/benefit-group';
import type { WebApp } from '@/types/web-app';
import { WebAppService } from '@/types/web-app-service';
import { WebAppServiceOption } from '@/types/web-app-service-option';
import type { WebAppTabs } from '@/types/web-app-tabs';

import BaseBadge from './base-badge.vue';
import ThePageHeader from './the-page-header.vue';
import WebAppServicesFormModal from './web-app-services-form-modal.vue';
import WebAppServicesIndexGroups from './web-app-services-index-groups.vue';
import WebAppServicesServiceCard from './web-app-services-service-card.vue';

interface Props {
  webApp: WebApp;
  backPath: string;
  tabs: WebAppTabs;
  webAppServices: WebAppService[];
  webAppServiceOptions: WebAppServiceOption[];
  benefitGroups: BenefitGroup[];
  benefitGroupsNeedImageAndDescription: boolean;
}
const props = defineProps<Props>();

const {
  data: webAppServicesData,
  refetch: refetchWebAppServices,
} = useQuery(['web-app-services', props.webApp.id],
  () => (webAppServiceApi.index(props.webApp.id)),
  { initialData: props.webAppServices, enabled: false, retry: false },
);
const updatedWebAppServices = computed(() => (webAppServicesData.value || []));
const noServices = computed(() => updatedWebAppServices.value.length === 0);
const notSelectedWebAppServiceOptions = computed(() => (
  props.webAppServiceOptions.filter((webAppServiceOption: WebAppServiceOption) =>
    !updatedWebAppServices.value.some((webAppService: WebAppService) =>
      webAppService.ownerId === webAppServiceOption.id &&
        webAppService.ownerType === webAppServiceOption.className,
    ),
  )
));

const {
  data: benefitGroupsData,
  refetch: refetchBenefitGroups,
}: { data: Ref<BenefitGroup[]>, refetch: Ref<() => void> } = useQuery(['benefit-groups', props.webApp.id],
  () => (benefitGroupApi.index(props.webApp.id)),
  { initialData: props.benefitGroups, enabled: false, retry: false },
);
const updatedBenefitGroups = computed(() => (benefitGroupsData.value || []));
const benefitGroupsWithServices = computed(() => updatedBenefitGroups.value.map(
  (benefitGroup) => ({
    ...benefitGroup,
    webAppServices: updatedWebAppServices.value.filter(
      (webAppService: WebAppService) => webAppService.benefitGroupId === benefitGroup.id,
    ).toSorted((a: WebAppService, b: WebAppService) => (
      a.position !== undefined && b.position !== undefined ? a.position - b.position : 0
    )),
  }),
));
const servicesWithoutGroup = computed(() => updatedWebAppServices.value.filter(
  (webAppService: WebAppService) => !webAppService.benefitGroupId,
));
const orderedServicesWithoutGroup = computed(() => servicesWithoutGroup.value.toSorted(
  (a: WebAppService, b: WebAppService) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
));
const draggableServicesWithoutGroup = ref(orderedServicesWithoutGroup.value);
watch(orderedServicesWithoutGroup, () => {
  draggableServicesWithoutGroup.value = orderedServicesWithoutGroup.value;
});

const VISIBILITY_TIMEOUT = 3000;
const isServiceSaveSuccess = ref(false);
const isBenefitGroupSaveSuccess = ref(false);
const isSaveSuccess = computed(() => isServiceSaveSuccess.value || isBenefitGroupSaveSuccess.value);
const successMode = ref<'edit' | 'create'>();
const serviceSuccessMessage = computed(() => `webAppServices.index.serviceSuccessMessage.${successMode.value}`);
const groupSuccessMessage = computed(() => `webAppServices.index.groupSuccessMessage.${successMode.value}`);
const successMessage = computed(() => {
  if (isServiceSaveSuccess.value) return serviceSuccessMessage.value;
  if (isBenefitGroupSaveSuccess.value) return groupSuccessMessage.value;

  return '';
});
const { isVisible: isSuccessMessageVisible } = useVisibilityTimeout(
  { condition: isSaveSuccess, timeout: VISIBILITY_TIMEOUT },
);
watch(isSuccessMessageVisible, (isVisible) => {
  if (isVisible) return;

  isServiceSaveSuccess.value = false;
  isBenefitGroupSaveSuccess.value = false;
});

const visibleServiceForm = ref(false);
const selectedWebAppService = ref<WebAppService>();
function openServiceModal() {
  visibleServiceForm.value = true;
}
function editService(webAppService: WebAppService) {
  selectedWebAppService.value = webAppService;
  openServiceModal();
}
function closeServiceModal() {
  visibleServiceForm.value = false;
  selectedWebAppService.value = undefined;
}
function serviceSaveSuccess() {
  isServiceSaveSuccess.value = true;
  successMode.value = selectedWebAppService.value ? 'edit' : 'create';
  closeServiceModal();
  refetchWebAppServices.value();
}

const deleteWebAppServiceModalOpen = ref(false);
function openDeleteServiceModal(webAppService: WebAppService) {
  selectedWebAppService.value = webAppService;
  deleteWebAppServiceModalOpen.value = true;
}
function cancelDeleteWebAppService() {
  deleteWebAppServiceModalOpen.value = false;
  selectedWebAppService.value = undefined;
}
const {
  mutate: deleteWebAppServiceMutate,
} = useMutation(
  (webAppService: WebAppService) => webAppServiceApi.delete(webAppService.id),
  { onSuccess: () => { refetchWebAppServices.value(); } },
);
async function deleteWebAppService() {
  await deleteWebAppServiceMutate(selectedWebAppService.value);
  deleteWebAppServiceModalOpen.value = false;
  selectedWebAppService.value = undefined;
}

function benefitGroupSaveSuccess(benefitSuccessMode: 'edit' | 'create') {
  refetchBenefitGroups.value();
  isBenefitGroupSaveSuccess.value = true;
  successMode.value = benefitSuccessMode;
}
function benefitGroupDeleteSuccess() {
  refetchBenefitGroups.value();
  refetchWebAppServices.value();
}

const draggableTarget = ref<HTMLElement>();
function setTarget(event: DraggableEvent) {
  draggableTarget.value = event.to;
}
function clearTarget() {
  draggableTarget.value = undefined;
}
</script>

<template>
  <the-web-app-layout
    :web-app="webApp"
    selected-tab="webAppServices"
    :tabs="tabs"
    :back-path="backPath"
  >
    <the-page-header
      :title="$t('webAppServices.index.title')"
      :description="$t('webAppServices.index.subtitle')"
      emoji="🔑"
    >
      <template #notice>
        <base-badge
          v-if="isSuccessMessageVisible"
          :label="$t(successMessage)"
          color="success"
          theme="dark"
          data-testid="service-success-badge"
        />
      </template>
    </the-page-header>
    <div
      v-if="noServices"
      class="mx-auto flex w-52 flex-col items-center justify-center text-center"
    >
      <div class="mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-primary-100">
        <base-svg
          class="h-10 w-10 stroke-primary-700"
          name="clipboard"
        />
      </div>
      <p class="mb-4 font-semibold text-black">
        {{ $t('webAppServices.index.noServices') }}
      </p>
      <p class="mb-6 text-sm text-gray-500">
        {{ $t('webAppServices.index.createFirstService') }}
      </p>
      <base-button
        data-testid="add-first-service-button"
        theme="primary"
        icon-file-name="plus-circle"
        icon-position="start"
        label-i18n-path="webAppServices.index.addServiceButton"
        size="sm"
        @click="openServiceModal"
      />
    </div>
    <template v-else>
      <div
        class="mb-6"
      >
        <span class="mr-8 font-semibold text-gray-900">
          {{ $t('webAppServices.index.addServiceTitle') }}
        </span>
        <base-button
          data-testid="add-service-button"
          theme="secondary"
          icon-file-name="add"
          icon-position="start"
          label-i18n-path="webAppServices.index.addServiceButton"
          size="xs"
          @click="openServiceModal"
        />
      </div>
      <span class="mb-8 text-sm text-gray-900">
        {{ $t('webAppServices.index.addServiceSubtitle') }}
      </span>
      <VueDraggable
        v-model="draggableServicesWithoutGroup"
        data-testid="web-app-services-list"
        class="mb-8 grid grid-cols-4 gap-4 rounded-lg border border-gray-100 px-4 py-8 xl:grid-cols-5"
        :group="{name: 'services', put: false}"
        :sort="false"
        ghost-class="border-primary-300"
        drag-class="border-primary-300"
        animation="150"
        @move="setTarget"
        @end="clearTarget"
      >
        <web-app-services-service-card
          v-for="(webAppService, index) in draggableServicesWithoutGroup"
          :key="`${webAppService.id}-${index}`"
          class="cursor-grab"
          :web-app-service="webAppService"
          @edit="editService"
          @delete="openDeleteServiceModal"
        />
      </VueDraggable>
      <web-app-services-index-groups
        v-model:draggableTarget="draggableTarget"
        :web-app="webApp"
        :benefit-groups-with-services="benefitGroupsWithServices"
        :benefit-groups-need-image-and-description="benefitGroupsNeedImageAndDescription"
        @saved-group="benefitGroupSaveSuccess"
        @deleted-group="benefitGroupDeleteSuccess"
        @edit-service="editService"
        @delete-service="openDeleteServiceModal"
        @moved-service="refetchWebAppServices"
      />
    </template>
  </the-web-app-layout>
  <web-app-services-form-modal
    v-if="visibleServiceForm"
    :web-app-service="selectedWebAppService"
    :open="visibleServiceForm"
    :has-loyalty="webApp.hasLoyalty"
    :web-app-id="webApp.id"
    :is-sales-web-app="webApp.appType === 'sales'"
    :web-app-service-options="notSelectedWebAppServiceOptions"
    @save="serviceSaveSuccess"
    @close="closeServiceModal"
  />
  <base-mok-alert-modal
    :is-open="deleteWebAppServiceModalOpen"
    :icon="PhWarning"
    icon-color="warning"
    :title="$t('webAppServices.index.deleteServiceModal.title')"
    :confirm-label="$t('actions.delete')"
    :cancel-label="$t('actions.cancel')"
    @confirm="deleteWebAppService"
    @cancel="cancelDeleteWebAppService"
    @close="cancelDeleteWebAppService"
  >
    <p>
      {{ $t('webAppServices.index.deleteServiceModal.description') }}
    </p>
  </base-mok-alert-modal>
</template>
