<script setup lang="ts">
import { PhX } from '@phosphor-icons/vue';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { computed, ref, type Component } from 'vue';

import BaseMokButton from '@/components/base-mok-button.vue';
import BaseMokIcon from '@/components/base-mok-icon.vue';
import BaseMokModal from '@/components/base-mok-modal.vue';

export type Props = {
  isOpen: boolean;
  /** The svg of the icon. Search svgs in https://phosphoricons.com/ */
  icon: Component;
  title: string;
  /** Label of the secondary button. Without it the secondary button won't be visible */
  cancelLabel?: string;
  /** Label of the primary button. Without it the primary button won't be visible */
  confirmLabel?: string;
  /** Shows loading in primary button */
  loading?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  cancelLabel: undefined,
  confirmLabel: undefined,
  loading: false,
});

const emits = defineEmits<{(e: 'close'): void, (e: 'confirm'): void}>();

function closeModal() {
  emits('close');
}

const { greaterOrEqual } = useBreakpoints(breakpointsTailwind);
const smOrLarger = greaterOrEqual('sm');
const iconSize = computed(() => (smOrLarger.value ? 'xl' : 'lg'));
const closeIconSize = computed(() => (smOrLarger.value ? '24px' : '16px'));
const focusCancelButton = computed(() => (!props.confirmLabel));
const focusCloseButton = computed(() => (!props.confirmLabel && !props.cancelLabel));
const unfocusedRef = ref<HTMLElement>();
const buttonsSize = computed(() => (smOrLarger.value ? 'large' : 'medium'));

function setUnfocusedRef(element: HTMLElement) {
  unfocusedRef.value = element;

  return unfocusedRef;
}

</script>

<template>
  <base-mok-modal
    class="py-4 sm:py-6"
    :is-open="isOpen"
    @close="closeModal"
  >
    <template #default="{ setInitialFocusRef }">
      <header class="relative flex grow flex-col items-center gap-y-1 px-6 text-center">
        <base-mok-icon
          v-if="icon"
          :icon="icon"
          :size="iconSize"
        />
        <h3 class="text-xl font-semibold leading-8 text-gray-900">
          {{ title }}
        </h3>
        <div class="text-sm font-normal leading-4 text-gray-800 sm:text-base">
          <slot name="description" />
        </div>
        <button
          :ref="focusCloseButton ? setInitialFocusRef : setUnfocusedRef"
          data-testid="close-button"
          class="absolute right-6 top-0"
          @click="closeModal"
        >
          <PhX
            :size="closeIconSize"
            class="fill-gray-400"
          />
        </button>
      </header>
      <div class="mt-6 max-h-60 overflow-y-auto px-6 pb-4">
        <slot />
      </div>
      <div
        data-testid="buttons-container"
        class="flex flex-col-reverse gap-y-4 px-6 sm:flex-row"
        :class="{
          'grid-cols-2 gap-x-6 sm:grid': cancelLabel && confirmLabel,
          'border-t border-gray-200 pt-3 sm:pt-6': cancelLabel || confirmLabel
        }"
      >
        <base-mok-button
          v-if="cancelLabel"
          :ref="focusCancelButton ? setInitialFocusRef : setUnfocusedRef"
          variant="secondary"
          :size="buttonsSize"
          full-width
          :label="cancelLabel"
          @click="$emit('close')"
        />
        <base-mok-button
          v-if="confirmLabel"
          :ref="setInitialFocusRef"
          variant="primary"
          :size="buttonsSize"
          full-width
          :label="confirmLabel"
          :loading="loading"
          @click="$emit('confirm')"
        />
      </div>
    </template>
  </base-mok-modal>
</template>
