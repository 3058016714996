<script setup lang="ts">
import type { WebAppTabName } from '@/types/web-app-tab-name';
import type { WebAppTabs } from '@/types/web-app-tabs';

const props = defineProps<{
  title: string;
  subtitle: string;
  imageUrl?: string;
  selectedTab: WebAppTabName;
  tabs: WebAppTabs;
  backPath: string;
}>();

function isSelected(tabName: WebAppTabName) {
  return props.selectedTab === tabName;
}

const icons = {
  webApp: 'menu-list',
  sections: 'art',
  countryServices: 'clipboard',
  webAppServices: 'clipboard',
  integrations: 'network',
  functionalities: 'stars',
};

</script>

<template>
  <div class="flex flex-col border-r border-gray-300 bg-white px-2 md:px-8  2xl:px-10">
    <div class="flex h-screen flex-col space-y-4 md:pb-4">
      <div class="flex flex-col items-center justify-center space-x-4 overflow-hidden text-ellipsis pt-8 md:flex-row md:justify-start md:space-x-6 lg:py-8">
        <base-rounded-logo
          :image-url="imageUrl"
        />
        <div class="flex flex-col space-y-2">
          <h1 class="hidden font-semibold text-black md:flex lg:text-xl">
            {{ title }}
          </h1>
          <h2 class="hidden text-sm text-gray-900 md:flex">
            {{ subtitle }}
          </h2>
        </div>
      </div>
      <h2 class="hidden text-sm font-semibold uppercase text-gray-500 md:block">
        {{ $t('webApp.titles.show') }}
      </h2>
      <div class="flex h-min flex-col space-y-2 md:grow">
        <base-sidebar-tab
          v-for="(tabPath, tabName) in tabs"
          :key="`${tabName}-tab`"
          :icon="icons[tabName]"
          :selected="isSelected(tabName)"
          :label="`${tabName}.title`"
          :href="tabPath"
        />
      </div>
      <base-sidebar-tab
        icon="back"
        label="webApp.actions.back"
        :href="backPath"
      />
    </div>
  </div>
</template>
