import { decamelize } from 'humps';
import { serialize } from 'object-to-formdata';

import type { Section } from '@/types/section';
import type { SectionBaseTypeName } from '@/types/section-base-type-name';

import api from './index';

const BASE_PATH = '/api/internal/sections';

interface SectionUpdateProps {
  sectionId: number;
  sectionData: {
    type: string,
    sectionFields?: Record<string, unknown> };
}

interface SectionCreateProps {
  baseType: SectionBaseTypeName;
  webAppId: number;
}

export async function update({ sectionId, sectionData }: SectionUpdateProps): Promise<{ section: Section }> {
  const data = serialize({
    section: { ...sectionData },
  });
  const path = `${BASE_PATH}/${sectionId}`;

  const response = await api({
    method: 'patch',
    url: path,
    data,
  });

  return response.data;
}

export async function destroy(sectionId: number): Promise<{ section: Section }> {
  const path = `${BASE_PATH}/${sectionId}`;

  const response = await api({
    method: 'delete',
    url: path,
  });

  return response.data;
}

export async function create({ baseType, webAppId }: SectionCreateProps): Promise<{ section: Section }> {
  const path = `${BASE_PATH}/${decamelize(baseType)}`;
  const data = serialize({
    section: { webAppId, view: 'home' },
  });
  const response = await api({
    method: 'post',
    url: path,
    data,
  });

  return response.data;
}
