<script setup lang="ts">
import omit from 'lodash/omit';
import { reactive, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMutation } from 'vue-query';
import * as yup from 'yup';

import webAppServiceApi from '@/api/webAppService';
import useInitialLoyaltyValues from '@/composables/useInitialLoyaltyValues';
import {
  WebAppServiceForm, WebAppService, DigitalProviderWebAppService, MoksysWebAppService,
} from '@/types/web-app-service';
import { WebAppServiceOption } from '@/types/web-app-service-option';

import BaseRadioInputGroup from './base-radio-input-group.vue';
import BaseSearchSelect from './base-search-select.vue';
import
WebAppServicesFormModalExtraInformationTextInputs
  from './web-app-services-form-modal-extra-information-text-inputs.vue';
import WebAppServicesFormModalLoyaltyInputs from './web-app-services-form-modal-loyalty-inputs.vue';
import WebAppServicesServiceKindBadge from './web-app-services-service-kind-badge.vue';

interface Props {
  webAppService?: WebAppService;
  open: boolean;
  hasLoyalty: boolean;
  webAppId: number;
  isSalesWebApp: boolean;
  webAppServiceOptions: WebAppServiceOption[];
}
const props = defineProps<Props>();
interface Emits {
  (event: 'close'): void;
  (event: 'save') : void;
}
const emit = defineEmits<Emits>();

const { t } = useI18n({});

const selectedWebAppServiceOwner = ref<WebAppServiceOption>();
const isCreatingSalesService = ref(false);
const isEditing = computed(() => !!props.webAppService);
const isCreatingProductiveService = computed(() => !!selectedWebAppServiceOwner.value);
const isSelectingWebAppServiceOwner = computed(
  () => !isCreatingProductiveService.value && !isCreatingSalesService.value && !isEditing.value,
);
const baseService = computed(() => props.webAppService || selectedWebAppServiceOwner.value);

const loyaltyType = ref();
const isRedeemable = computed(() => loyaltyType.value === 'redeemable');
const isIncluded = computed(() => loyaltyType.value === 'included');
const isRewardable = computed(() => loyaltyType.value === 'rewardable');

const webAppServiceDefaults = {
  hasCarInputs: false,
  accumulationRate: 0.0,
};
const hasExtraInformationText = ref(false);
const initialWebAppServiceData = {
  ...webAppServiceDefaults,
  ...omit<Partial<WebAppService>>(props.webAppService, ['ownerId', 'ownerType', 'benefitGroupId', 'position']),
};
const webAppServiceData = reactive<WebAppServiceForm>({ ...initialWebAppServiceData });
function hasDigitalProviderKind(service: WebAppServiceForm): service is Partial<DigitalProviderWebAppService> {
  return !!service.kind && ['redirect', 'authenticated_redirect'].includes(service.kind);
}
function hasMoksysKind(service: WebAppServiceForm): service is Partial<MoksysWebAppService> {
  return !!service.kind && ['immediate', 'scheduled'].includes(service.kind);
}

watch(selectedWebAppServiceOwner, (newSelectedWebAppServiceOwner) => {
  if (!newSelectedWebAppServiceOwner) return;

  Object.assign(
    webAppServiceData,
    {
      type: newSelectedWebAppServiceOwner.webAppServiceType,
      ownerId: newSelectedWebAppServiceOwner.id,
      ownerType: newSelectedWebAppServiceOwner.className,
      ...omit(newSelectedWebAppServiceOwner, ['id', 'className', 'webAppServiceType']),
    },
  );
});

const { initialLoyaltyType, initialRedemptionKind } = useInitialLoyaltyValues(baseService);
watch(() => isSelectingWebAppServiceOwner.value, () => {
  if (isSelectingWebAppServiceOwner.value) return;
  if (hasMoksysKind(webAppServiceData)) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = webAppServiceData.extraInformationText || '';

    const text = tempDiv.textContent?.trim();
    hasExtraInformationText.value = !!text;

    if (!text) webAppServiceData.extraInformationText = '';
  }

  webAppServiceData.redemptionKind = initialRedemptionKind.value;
  loyaltyType.value = initialLoyaltyType.value;
},
{ immediate: true },
);

const webAppServiceSchema = computed(() => {
  const shape: yup.ObjectShape = {
    name: yup.string().required().label(t('webAppServices.form.fields.name')),
    description: yup.string().required(t('webAppServices.form.fields.description')),
    loyaltyType: yup.string().label(t('webAppServices.form.fields.loyaltyType')),
    accumulationRate: yup.number().test(
      'decimal-validation',
      t('webAppServices.form.fields.maxDecimals'),
      (value: number | undefined) => {
        const regex = /^\d+(\.\d{1,4})?$/;
        const stringValue = value?.toString() ?? '';

        return regex.test(stringValue) || !isRewardable.value || !props.hasLoyalty;
      },
    ).when(
      'type',
      {
        is: 'rewardable',
        then: (schema) => schema.moreThan(0),
        otherwise: (schema) => schema.min(0),
      },
    )
      .label(t('webAppServices.form.fields.accumulationRate')),
    pointCost: yup.number().when(
      'type',
      {
        is: 'redeemable',
        then: (schema) => schema.moreThan(0),
        otherwise: (schema) => schema.min(0),
      },
    )
      .label(t('webAppServices.form.fields.pointCost')),
    redemptionKind: yup.string().when(
      'type',
      {
        is: 'redeemable',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      },
    )
      .label(t('webAppServices.form.fields.redemptionKind')),
  };
  if (hasMoksysKind(webAppServiceData)) {
    shape.extraInformationText = yup
      .string()
      .transform((value) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = value;

        return tempDiv.textContent?.trim();
      })
      .when('hasExtraInformationText', {
        is: true,
        then: (schema) => schema.required().min(1),
        otherwise: (schema) => schema.notRequired(),
      })
      .label(t('webAppServices.form.fields.extraInformationText.label'));
  }
  if (isCreatingSalesService.value) {
    shape.kind = yup.string().required().label(t('webAppServices.form.fields.kind'));
    if (hasDigitalProviderKind(webAppServiceData)) {
      shape.link = yup.string().url().required()
        .label(t('webAppServices.form.fields.link'));
    }
  }

  return yup.object().shape(shape);
});

function startSalesServiceCreation() {
  isCreatingSalesService.value = true;
}
watch(() => isCreatingSalesService.value, (newValue) => {
  if (!newValue) return;

  webAppServiceData.kind = 'redirect';
  webAppServiceData.isSales = true;
});
watch(() => webAppServiceData.kind, (newValue) => {
  if (!newValue) return;

  if (hasDigitalProviderKind(webAppServiceData)) {
    webAppServiceData.type = 'WebAppServices::DigitalProvider';
  } else if (hasMoksysKind(webAppServiceData)) {
    webAppServiceData.type = 'WebAppServices::Moksys';
  }
});

const webAppServiceDataWithCorrectedLoyaltyValues = computed(() => {
  const values = { ...webAppServiceData };

  if (isIncluded.value) {
    values.pointCost = 0;
    values.accumulationRate = 0.0;
    values.redemptionKind = undefined;
  } else if (isRedeemable.value) {
    values.accumulationRate = 0.0;
  } else if (isRewardable.value) {
    values.pointCost = 0;
    values.redemptionKind = undefined;
  }

  return values;
});
const webAppServiceDataWithCorrectedValues = computed(() => {
  const values = { ...webAppServiceDataWithCorrectedLoyaltyValues.value };

  if (!hasExtraInformationText.value) {
    delete (values as Partial<MoksysWebAppService>).extraInformationText;
  }

  return values;
});
function saveWebAppServiceRequest() {
  if (props.webAppService) {
    return webAppServiceApi.update(props.webAppService.id, webAppServiceDataWithCorrectedValues.value);
  }

  return webAppServiceApi.create(props.webAppId, webAppServiceDataWithCorrectedValues.value);
}
const { mutate: saveWebAppService, isError, isLoading } = useMutation(
  saveWebAppServiceRequest,
  { onSuccess: () => emit('save') },
);

function close() {
  emit('close');
}

const kindOptions = computed(() => (
  ['redirect', 'immediate', 'scheduled'].map((kind) => ({
    value: kind, label: t(`webAppServices.form.fields.kinds.${kind}`),
  }))
));
const hasCarInputsOptions = computed(() => ([
  { value: true, label: t('webAppServices.form.fields.hasCarInputsOptions.true') },
  { value: false, label: t('webAppServices.form.fields.hasCarInputsOptions.false') },
]));
</script>

<template>
  <base-modal
    :is-open="open"
    @close="close"
  >
    <template #content>
      <div class="flex flex-1 flex-col overflow-y-hidden">
        <div class="flex items-center gap-2 border-b border-gray-200 pb-6">
          <div class="flex h-8 w-8 items-center justify-center rounded-full bg-primary-100">
            <base-svg
              class="h-6 w-6 stroke-primary-700"
              name="clipboard"
            />
          </div>
          <h2 class="text-lg font-semibold text-gray-900">
            {{ $t('webAppServices.form.addServiceTitle') }}
          </h2>
        </div>
        <template v-if="isSelectingWebAppServiceOwner">
          <div class="flex-1 overflow-y-auto">
            <div class="space-y-4 p-8">
              <base-search-select
                v-model="selectedWebAppServiceOwner"
                :options="webAppServiceOptions"
                input-name="webAppServiceOwner"
              >
                <template #option="{ option, index, activeIndex }">
                  <div
                    class="flex truncate border-x border-t px-6 py-4 first:rounded-t-md last:rounded-b-md last:border-b"
                    :class="{
                      'border-primary-200 bg-primary-25 text-primary-700': activeIndex === index,
                      'border-gray-200 bg-white text-gray-900 ': activeIndex !== index,
                      'border-t-primary-200': activeIndex === index - 1,
                    }"
                  >
                    {{ option.name }}
                    <web-app-services-service-kind-badge
                      class="ml-auto self-center px-2 py-0.5 text-2xs leading-3"
                      :kind="(option as WebAppServiceOption).kind"
                    />
                  </div>
                </template>
              </base-search-select>
              <div v-if="isSalesWebApp">
                <span class="mr-4 text-sm">
                  {{ $t('webAppServices.form.addSalesService') }}
                </span>
                <base-button
                  type="button"
                  size="sm"
                  theme="primary-link"
                  label-i18n-path="actions.add"
                  icon-file-name="add"
                  data-testid="add-sales-service"
                  @click="startSalesServiceCreation"
                />
              </div>
            </div>
            <div class="flex justify-end space-x-3 border-t border-gray-200 pt-6">
              <base-button
                type="button"
                theme="secondary"
                label-i18n-path="actions.cancel"
                @click="close"
              />
              <base-button
                type="button"
                disabled
                theme="primary"
                label-i18n-path="actions.save"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <v-form
            validate-on-mount
            :validation-schema="webAppServiceSchema"
            class="flex flex-col overflow-y-auto"
            @submit="saveWebAppService"
          >
            <div class="flex-1 overflow-y-auto py-6">
              <div
                v-if="baseService"
                class="mb-4 flex"
              >
                <div
                  class="mr-8 text-sm font-semibold leading-8 text-gray-900"
                >
                  {{ baseService.name }}
                </div>
                <div
                  v-if="hasMoksysKind(webAppServiceData) && webAppServiceData.moksysServiceId"
                  class="mr-4 self-center rounded-full bg-primary-700 px-4 text-xs leading-6 text-white"
                >
                  {{ $t('webAppServices.form.idBadgePrefix') }} {{ webAppServiceData.moksysServiceId }}
                </div>
                <web-app-services-service-kind-badge
                  class="self-center px-4 text-xs leading-6"
                  :kind="baseService.kind"
                />
              </div>
              <base-error
                v-if="isError"
                :message="$t('webAppServices.errors.update')"
              />
              <div
                class="space-y-4"
              >
                <base-radio-input-group
                  v-if="isCreatingSalesService"
                  v-model="webAppServiceData.kind"
                  :options="kindOptions"
                  name="kind"
                  data-testid="kind-input"
                  :label="$t('webAppServices.form.fields.kind')"
                />
                <base-input
                  v-model="webAppServiceData.name"
                  data-testid="name-input"
                  label-i18n-path="webAppServices.form.fields.name"
                  :placeholder="$t('webAppServices.form.fields.name')"
                  name="name"
                />
                <base-rich-input
                  v-model="webAppServiceData.description"
                  data-testid="description-input"
                  label-i18n-path="webAppServices.form.fields.description"
                  name="description"
                  weight="bold"
                  :options="['bold', 'italic', 'bulletList', 'undo', 'redo']"
                />
                <base-image-input
                  v-model="webAppServiceData.image"
                  data-testid="image-input"
                  :label="$t('webAppServices.form.fields.image')"
                  name="image"
                  :preview-url="webAppServiceData.imageUrl"
                  :preview-name="webAppServiceData.imageFilename"
                />
                <base-radio-input-group
                  v-if="hasMoksysKind(webAppServiceData)"
                  v-model="webAppServiceData.hasCarInputs"
                  data-testid="has-car-inputs-input"
                  :options="hasCarInputsOptions"
                  name="hasCarInputs"
                  :label="$t('webAppServices.form.fields.hasCarInputs')"
                />
                <web-app-services-form-modal-loyalty-inputs
                  v-if="hasLoyalty"
                  v-model:loyalty-type="loyaltyType"
                  v-model:redemption-kind="webAppServiceData.redemptionKind"
                  v-model:accumulation-rate="webAppServiceData.accumulationRate as number"
                  v-model:point-cost="webAppServiceData.pointCost as number"
                />
                <base-input
                  v-if="webAppServiceData.isSales && hasDigitalProviderKind(webAppServiceData)"
                  v-model="webAppServiceData.link"
                  data-testid="link-input"
                  name="link"
                  label-i18n-path="webAppServices.form.fields.link"
                />
                <web-app-services-form-modal-extra-information-text-inputs
                  v-if="hasMoksysKind(webAppServiceData)"
                  v-model:has-extra-information-text="hasExtraInformationText"
                  v-model:extra-information-text="webAppServiceData.extraInformationText"
                  data-testid="web-app-services-form-modal-extra-information-text-inputs"
                />
              </div>
            </div>
            <div class="flex justify-end space-x-3 border-t border-gray-200 pt-6">
              <base-button
                type="button"
                theme="secondary"
                label-i18n-path="actions.cancel"
                @click="close"
              />
              <base-button
                type="submit"
                theme="primary"
                label-i18n-path="actions.save"
                :loading="isLoading"
              />
            </div>
          </v-form>
        </template>
      </div>
    </template>
  </base-modal>
</template>
