import VTooltip from 'floating-vue';
import { camelize, camelizeKeys } from 'humps';
import { Field, Form, ErrorMessage } from 'vee-validate';
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import { VueQueryPlugin } from 'vue-query';
import * as yup from 'yup';
import * as yupLocales from 'yup-locales';

import 'floating-vue/dist/style.css';
import '@rails/actiontext';
import '../css/application.css';

import BaseBadge from '../components/base-badge.vue';
import BaseButtonGroup from '../components/base-button-group.vue';
import BaseButton from '../components/base-button.vue';
import BaseCheckboxInput from '../components/base-checkbox-input.vue';
import BaseColorInput from '../components/base-color-input.vue';
import BaseError from '../components/base-error.vue';
import BaseIconButton from '../components/base-icon-button.vue';
import BaseImageField from '../components/base-image-field.vue';
import BaseImageInput from '../components/base-image-input.vue';
import BaseInfoSwitch from '../components/base-info-switch.vue';
import BaseInput from '../components/base-input.vue';
import BaseLogoInput from '../components/base-logo-input.vue';
import BaseModal from '../components/base-modal.vue';
import BaseMokAlertModal from '../components/base-mok-alert-modal.vue';
import BaseMokDropdown from '../components/base-mok-dropdown.vue';
import BaseMokIcon from '../components/base-mok-icon.vue';
import BaseMokInput from '../components/base-mok-input.vue';
import BasePill from '../components/base-pill.vue';
import BaseRadioInput from '../components/base-radio-input.vue';
import BaseRichInput from '../components/base-rich-input.vue';
import BaseRichText from '../components/base-rich-text.vue';
import BaseRoundedLogo from '../components/base-rounded-logo.vue';
import BaseSearch from '../components/base-search.vue';
import BaseSelect from '../components/base-select.vue';
import BaseSidebarTab from '../components/base-sidebar-tab.vue';
import BaseSlider from '../components/base-slider.vue';
import BaseSvgInput from '../components/base-svg-input.vue';
import BaseSvg from '../components/base-svg.vue';
import BaseSwitch from '../components/base-switch.vue';
import BaseTab from '../components/base-tab.vue';
import BaseTextField from '../components/base-text-field.vue';
import BaseTextSwitch from '../components/base-text-switch.vue';
import BaseTooltip from '../components/base-tooltip.vue';
import LoginForm from '../components/login-form.vue';
import PasswordForm from '../components/password-form.vue';
import RecoverPasswordForm from '../components/recover-password-form.vue';
import SectionsBooleanSectionField from '../components/sections/boolean-section-field.vue';
import SectionsColorSectionField from '../components/sections/color-section-field.vue';
import SectionsImageSectionField from '../components/sections/image-section-field.vue';
import SectionsRichTextSectionField from '../components/sections/rich-text-section-field.vue';
import SectionForm from '../components/sections/section-form.vue';
import SectionsTextSectionField from '../components/sections/text-section-field.vue';
import TheApplicationProvider from '../components/the-application-provider.vue';
import TheNavbar from '../components/the-navbar.vue';
import TheWebAppLayout from '../components/the-web-app-layout.vue';
import WebAppCountryServicesIndex from '../components/web-app-country-services-index.vue';
import WebAppFunctionalities from '../components/web-app-functionalities.vue';
import WebAppIntegrations from '../components/web-app-integrations.vue';
import WebAppServicesIndex from '../components/web-app-services-index.vue';
import WebAppShow from '../components/web-app-show.vue';
import WebAppsIndex from '../components/web-apps-index.vue';
import WebAppsNew from '../components/web-apps-new.vue';
import { useSiteVariables } from '../composables/useSiteVariables';
import Locales from '../locales/locales';

const { locale, localeWithoutCountryCode } = useSiteVariables();
const yupLocale = yupLocales[localeWithoutCountryCode as keyof typeof yupLocales];
if (yupLocale) yup.setLocale(yupLocale);

type LocalesSchema = typeof Locales.messages.esCL;

const i18n = createI18n<[LocalesSchema], 'esCL'>({
  legacy: false,
  locale,
  fallbackLocale: 'esCL',
  messages: Locales.messages,
});

// eslint-disable-next-line max-statements
document.addEventListener('DOMContentLoaded', () => {
  const app = createApp({
    components: {
      LoginForm,
      PasswordForm,
      RecoverPasswordForm,
      SectionForm,
      TheApplicationProvider,
      WebAppsIndex,
      WebAppsNew,
      WebAppCountryServicesIndex,
      WebAppServicesIndex,
      WebAppIntegrations,
      WebAppFunctionalities,
      WebAppShow,
    },
    methods: {
      camelizeKeys,
      camelize,
    },
  });
  app.component('BaseBadge', BaseBadge);
  app.component('BaseButton', BaseButton);

  app.component('BaseCheckboxInput', BaseCheckboxInput);
  app.component('BaseColorInput', BaseColorInput);
  app.component('BaseError', BaseError);
  app.component('BaseIconButton', BaseIconButton);
  app.component('BaseImageInput', BaseImageInput);
  app.component('BaseImageField', BaseImageField);
  app.component('BaseInfoSwitch', BaseInfoSwitch);
  app.component('BaseInput', BaseInput);
  app.component('BaseLogoInput', BaseLogoInput);
  app.component('BaseModal', BaseModal);
  app.component('BasePill', BasePill);
  app.component('BaseRadioInput', BaseRadioInput);
  app.component('BaseRoundedLogo', BaseRoundedLogo);
  app.component('BaseRichInput', BaseRichInput);
  app.component('BaseRichText', BaseRichText);
  app.component('BaseSearch', BaseSearch);
  app.component('BaseSelect', BaseSelect);
  app.component('BaseSvgInput', BaseSvgInput);
  app.component('BaseSwitch', BaseSwitch);
  app.component('BaseSidebarTab', BaseSidebarTab);
  app.component('BaseSlider', BaseSlider);
  app.component('BaseTab', BaseTab);
  app.component('BaseTextField', BaseTextField);
  app.component('BaseTextSwitch', BaseTextSwitch);
  app.component('BaseTooltip', BaseTooltip);
  app.component('BaseButtonGroup', BaseButtonGroup);
  app.component('BaseSvg', BaseSvg);
  app.component('BaseMokAlertModal', BaseMokAlertModal);
  app.component('BaseMokDropdown', BaseMokDropdown);
  app.component('BaseMokIcon', BaseMokIcon);
  app.component('BaseMokInput', BaseMokInput);
  app.component('SectionsBooleanSectionField', SectionsBooleanSectionField);
  app.component('SectionsColorSectionField', SectionsColorSectionField);
  app.component('SectionsImageSectionField', SectionsImageSectionField);
  app.component('SectionsRichTextSectionField', SectionsRichTextSectionField);
  app.component('SectionsTextSectionField', SectionsTextSectionField);
  app.component('TheWebAppLayout', TheWebAppLayout);
  app.component('TheNavbar', TheNavbar);
  app.component('VField', Field);
  app.component('VForm', Form);
  app.component('VErrorMessage', ErrorMessage);
  app.use(i18n);
  app.use(VTooltip);
  app.use(VueQueryPlugin);
  app.mount('#vue-app');

  return app;
});
